<template>
    <div style="min-height: 100vh;">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'basic-layout',
    data () {
        return {
        }
    },
}
</script>
