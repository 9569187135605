<template>
    <div class="container-flex">
        <div class="mobile-menu" :class="{'mobile-menu-active': mobile_menu_active}">
            <div class="header-mobile-menu">
                <div class="logo-mobile-menu">
                    <img src="../assets/logo_rc.png" alt="Ruben Cissé Logo">
                </div>
                <i class="fas fa-times mobile-menu-close" @click="toggle_mobile_menu()"></i>
            </div>
            <div>
                <a class="navbar-link noselect" @click="scroll_to('home')">Home</a>
            </div>
            <div>
                <a class="navbar-link noselect" @click="scroll_to('about')">About</a>
            </div>
            <div>
                <a class="navbar-link noselect" @click="scroll_to('portfolio')">Portfolio</a>
            </div>
            <div>
                <a class="navbar-link noselect" @click="scroll_to('contact')">Contact</a>
            </div>
        </div>
        <div class="container-navbar" :class="{ 'background-black': on_home }">
            <div class="navbar">
                <div class="navbar-logo">
                    <img src="../assets/logo_rc.png" alt="Ruben Cissé Logo">
                </div>
                <div class="navbar-links" :class="{ 'text-shadow-active': on_home }">
                    <a class="navbar-link noselect" :class="{ 'navbar-link-active': on_home }" @click="scroll_to('home')">Home</a>
                    <a class="navbar-link noselect" :class="{ 'navbar-link-active': on_about }" @click="scroll_to('about')">About</a>
                    <a class="navbar-link noselect" :class="{ 'navbar-link-active': on_portfolio }" @click="scroll_to('portfolio')">Portfolio</a>
                    <a class="navbar-link noselect" :class="{ 'navbar-link-active': on_contact }" @click="scroll_to('contact')">Contact</a>
                </div>
                <div class="button-mobile-menu">
                    <i class="fas fa-bars mobile-menu-open" @click="toggle_mobile_menu()"></i>
                </div>
            </div>
        </div>
        <intersect @enter="on_home = true" @leave="on_home = false" :threshold="[0.1]">
            <div class="section section-home" id="home">
                <div class="volume_controls" @click="toggle_showcase_sound">
                    <i v-if="!showcase_sound" class="fas fa-volume-mute"></i>
                    <i v-if="showcase_sound" class="fas fa-volume-up"></i>
                </div>
                <video autoplay muted loop id="video_autoplay">
                </video>
            </div>
        </intersect>
        <intersect @enter="on_about = true" @leave="on_about = false" :threshold="[0.3]">
            <div class="section section-about" id="about">
                <h2>About</h2>
                <div class="row container-content_about">
                    <intersect @enter="fade1 = true" @leave="fade1 = false">
                        <div class="col-lg-12 content_about fade-in" :class="{ 'fade-in-active': fade1 }">
                            <div class="container-text_about">
                                <p class="text_about" style="text-align: center;">
                                  I work as a freelancer, primarily focusing on projects in television production. In addition, I create Image Films for clients ranging from restaurants to fashion magazines.
                                  <br/><br/><br/>
                                  Ich bin als Freiberufler tätig und konzentriere mich vorrangig auf Projekte in der Fernsehproduktion. Darüber hinaus erstelle ich Imagefilme für verschiedene Branchen, darunter Restaurants, Reedereien und Modezeitschriften.
                                </p>
                            </div>
                        </div>
                    </intersect>
                    <intersect @enter="fade2 = true" @leave="fade2 = false">
                        <div class="container-btn-contact fade-in" :class="{ 'fade-in-active': fade2 }">
                            <h4>Got any questions or want to talk about working together on your personal project?</h4>
                            <button class="btn-contact" @click="scroll_to('contact')">contact me!</button>
                        </div>
                    </intersect>
                </div>
            </div>
        </intersect>
        <intersect @enter="on_portfolio = true" @leave="on_portfolio = false" :threshold="[0.4]">
            <div class="section section-portfolio" id="portfolio">
                <h2>Portfolio</h2>
                <intersect @enter="fade3 = true" @leave="fade3 = false">
                    <div class="row container-portfolio fade-in" :class="{ 'fade-in-active': fade3 }">
                        <div class="col-lg-4 col-md-6 container-portfolio-tile">
                            <div class="portfolio-tile">
                                <img class="portfolio-image" src="../assets/portfolio_thumbnails/t_bali.jpg" alt="Bali Thumbnail">
                                <div class="portfolio-overlay" data-toggle="modal" data-target="#showcaseModal" @click="set_showcase_src('https://player.vimeo.com/video/573686782?badge=0')">
                                    Bali
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 container-portfolio-tile">
                            <div class="portfolio-tile">
                                <img class="portfolio-image" src="../assets/portfolio_thumbnails/t_laeufer.jpg" alt="Justine Laeufer Thumbnail">
                                <div class="portfolio-overlay" data-toggle="modal" data-target="#showcaseModal" @click="set_showcase_src('https://player.vimeo.com/video/615095635?badge=0')">
                                    Justine Laeufer Portrait
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 container-portfolio-tile">
                            <div class="portfolio-tile">
                                <img class="portfolio-image" src="../assets/portfolio_thumbnails/t_rr.jpg" alt="Rolls Royce Thumbnail">
                                <div class="portfolio-overlay" data-toggle="modal" data-target="#showcaseModal" @click="set_showcase_src('https://player.vimeo.com/video/599358110?badge=0')">
                                    Rolls Royce
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 container-portfolio-tile">
                            <div class="portfolio-tile">
                                <img class="portfolio-image" src="../assets/portfolio_thumbnails/t_shibuya.jpg" alt="Shibuya Thumbnail">
                                <div class="portfolio-overlay" data-toggle="modal" data-target="#showcaseModal" @click="set_showcase_src('https://player.vimeo.com/video/573722017?badge=0')">
                                    Shibuya 26
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 container-portfolio-tile">
                            <div class="portfolio-tile">
                                <img class="portfolio-image" src="../assets/portfolio_thumbnails/t_dji.jpg" alt="DJI Thumbnail">
                                <div class="portfolio-overlay" data-toggle="modal" data-target="#showcaseModal" @click="set_showcase_src('https://player.vimeo.com/video/573722017?badge=0')">
                                    DJI Academy
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 container-portfolio-tile">
                            <div class="portfolio-tile">
                                <img class="portfolio-image" src="../assets/portfolio_thumbnails/t_bestfashion.jpg" alt="Best Fashion Thumbnail">
                                <div class="portfolio-overlay" data-toggle="modal" data-target="#showcaseModal" @click="set_showcase_src('https://player.vimeo.com/video/616233962?badge=0')">
                                    Best Fashion BTS
                                </div>
                            </div>
                        </div>
                    </div>
                </intersect>
            </div>
        </intersect>
        <intersect @enter="on_contact = true" @leave="on_contact = false" :threshold="[0.4]">
            <div class="section section-contact" id="contact">
                <div class="container-contact">
                    <form @submit.prevent="handle_submit" class="contact-form" name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                        <p style="display: none;" class="hidden">
                            <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <h2>Contact me</h2>
                        <intersect @enter="fade4 = true" @leave="fade4 = false">
                            <div class="fade-in" :class="{ 'fade-in-active': fade4 }">
                                <div class="form-group">
                                    <label for="firstname">First Name</label>
                                    <input v-model="form.firstname" class="form-control" id="firstname" name="firstname" type="text">
                                </div>
                                <div class="form-group">
                                    <label for="lastname">Last Name</label>
                                    <input v-model="form.lastname" class="form-control" id="lastname" name="lastname" type="text">
                                </div>
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input v-model="form.email" class="form-control" id="email" name="email" type="email">
                                </div>
                                <div class="form-group">
                                    <label for="phone">Phone</label>
                                    <input v-model="form.phone" class="form-control" id="phone" name="phone" type="number">
                                </div>
                                <div class="form-group">
                                    <label for="message">Your message</label>
                                    <textarea v-model="form.message" class="form-control" rows="5" id="message" name="message"></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary btn-contact-submit" data-toggle="modal" data-target="#formModal">Send</button>
                            </div>
                        </intersect>
                    </form>
                </div>
                <div class="container-btt">
                    <button class="btn-btt" @click="back_to_top">back to top</button>
                </div>
            </div>
        </intersect>
        <div class="container-imprint">
            <router-link to="/imprint"><a>Impressum</a></router-link>
        </div>
        <div class="modal fade" id="showcaseModal" tabindex="-1" role="dialog" aria-labelledby="showcaseModalLabel" aria-hidden="true" @click="close_showcase">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close_showcase">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe id="iframe_showcase" class="embed-responsive-item" src="" allowscriptaccess="always" allow="fullscreen"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="modal fade" id="formModal" tabindex="-1" role="dialog" aria-labelledby="formModalLabel" aria-hidden="false" >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="formModal-message">
                            {{ form_modal_message }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container-btn-contact {
    margin-top: 15px;
    text-align: center;
    width: 100%;
    position: relative;
    padding: 0 10px;
}
.volume_controls {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 24px;
    color: white;
    transition: .3s;
    display: none;
}
.volume_controls:hover {
    cursor: pointer;
    color: #4ac1ed;
}
.close {
    position: absolute;
    right: -30px;
    color: white;
    opacity: 1;
    transition: .3s;
    text-shadow: none;
    font-size: 34px;
}
.close:hover {
    color: #4ac1ed;
    border: none;
    text-shadow: none;
    opacity: 1 !important;
}
.modal-dialog {
    max-width: 50%;
    margin: 30px auto;
    top: 15%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.modal-body {
    position:relative;
    padding:0px;
    background-color: black;
}
.client-col {
    padding: 0 25px;
    flex: 0 0 25%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 50px 0;
}
.container-clients {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 75px;
}
.container-clients img {
    width: auto;
    height: auto;
    max-width: 150px;
    max-height: 100px;
}
.container-imprint {
    width: 100%;
    text-align: center;
    height: 50px;
    padding-bottom: 30px;
}
.container-imprint a {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Antonio';
    line-height: 1;
    font-size: 20px;
    text-align: center;
    color: white;
    transition: .3s;
}
.container-imprint a:hover {
    color: #4ac1ed;
    text-decoration: none;;
}
.navbar-link:hover {
    text-decoration: none;
}
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth !important;
  }
}
.fade-in {
    opacity: 0;
    transition: ease-in-out 1.75s;
    bottom: -35px;
    position: relative;
}
.fade-in-active {
    opacity: 1;
    bottom: 0px;
}
.container-text_about {
    flex: 1;
}
.icon_about i {
    font-size: 30px;
    padding-right: 15px;
    padding-top: 5px;
    float: left;
    color: #4ac1ed;
}
.container-portfolio-tile {
    padding: 5px;
}
.container-portfolio {
    padding: 0px;
    margin: 0;
}
.content_about {
    padding: 0px 75px;
    display: flex;
}
.text_about {
    text-align: justify;
    color: white;
    margin-bottom: 100px;
    letter-spacing: 1px;
    font-family: 'Abeezee';
    margin-top: 15px;
}
.container-content_about {
    width: 75%;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
}
.container-btt {
    padding: 50px 0px;
    text-align: center;
}
.btn-contact {
    margin-top: 15px;
}
.btn-btt, .btn-contact {
    font-family: 'Antonio';
    text-transform: uppercase;
    border: 2px solid #4ac1ed;
    border-radius: 0;
    color: #4ac1ed;
    background-color: black;
    transition: .3s;
    padding: 15px 25px;
}
.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.btn-btt:hover, .btn-contact:hover {
    background-color: #4ac1ed;
    color: black;
}
.navbar-link-active {
    color: #4ac1ed !important;
    text-shadow: none !important;
}
.section {
    padding: 75px 0px;
    display: grid;
    align-items: center;
}
.section-about {
    padding: 250px 0px;
}
.portfolio-overlay {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    transition: .5s;
    background-color: #4ac1edbc;
    font-size: 8px;
    color: white;
    font-family: 'antonio';
    position: absolute;
}
.portfolio-overlay:hover {
    cursor: pointer;
    font-size: 28px;
    opacity: 1;
}
.portfolio-tile {
    position: relative;
    padding-bottom: 56.2%;
}
.portfolio-image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.container-slider_img {
    height: 100px;
    text-align: center;
    width: 25%;
}
.slider_img {
    height: 100%;
}
.container-contact {
    width: 100%;
    margin: 0 auto;
    display: flex;
}
.container-brands {
    padding: 125px 100px;
    padding-top: 0;
    justify-content: space-between;
}
.section h2 {
    text-align: center;
    margin-bottom: 50px;
}
.btn-contact-submit {
    border: 1px solid #4ac1ed;
    border-radius: 0;
    background-color: transparent;
    color: #4ac1ed;
    transition: .3s;
    box-shadow: none !important;
    text-transform: uppercase;
    font-family: 'Antonio';
    letter-spacing: 2px;
    padding: 10px 25px;
}
.btn-contact-submit:hover, .btn-contact-submit:active, .btn-contact-submit:focus {
    background-color: #4ac1ed;
    border-color: #4ac1ed;
    color: black;
}
.contact-form {
    width: 30%;
    margin: 0 auto;
}
.contact-form label {
    color: white;
    font-family: 'Antonio';
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
}
.contact-form input, .contact-form textarea {
    border-radius: 0;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    font-family: 'Antonio';
    padding: 15px 10px;
    letter-spacing: 1px;
}
.contact-form input {
    padding: 25px 10px;
}
.contact-form input:focus {
    border-color: #4ac1ed;
    box-shadow: none;
    color: white;
}
.testimonials {
    padding: 50px 0;
}
.testimonial-text {
    font-family: 'Abeezee';
    font-style: italic;
    font-size: 26px;
    color: #4ac1ed;
}
.testimonial-author {
    font-family: 'Abeezee';
    margin-top: 25px;
    color: lightgray;
    font-size: 18px;
    font-weight: bold;
}

h1, h2, h3, h4, h5 {
    font-family: 'Antonio';
    color: white;
    text-transform: uppercase;
}
.section-clients {
    width: 100%;
}
.mobile-menu-close {
    position: absolute;
    right: 30px;
    line-height: 35px;
    transition: .3s;
}
.mobile-menu-close:hover {
    cursor: pointer;
    color: #4ac1ed;
}
.mobile-menu {
    display: none;
    height: 100vh;
    width: 100%;
    left: -100%;
    transition: ease-in-out .3s;
    top: 0;
    position: fixed;
    background-color: black;
    z-index: 101;
    color: white;
    padding: 15px;
    text-transform: uppercase;
    font-family: "Antonio";
    font-size: 24px;
    letter-spacing: 1px;
}
.logo-mobile-menu img {
    height: 50px;
}
.logo-mobile-menu {
    margin-bottom: 0 !important;
}
.header-mobile-menu {
    display: flex;
    align-items: center;
    margin-bottom: 50px !important;
}
.mobile-menu h1 {
    margin-bottom: 50px;
    font-size: 35px;
    line-height: 1;
}
.mobile-menu div {
    margin-bottom: 15px;
}
.mobile-menu-active {
    left: 0;
}
.mobile-menu-open {
    font-size: 20px;
    line-height: 35px;
    transition: .3s;
}
.mobile-menu-open:hover {
    color: #4ac1ed;
    cursor: pointer;
}
.button-mobile-menu {
    display: none;
    position: fixed;
    right: 30px;
}
#video_autoplay {
    width: 100%;
    height: auto;
}
.container-testimonials {
    text-align: center;
    width: 25%;
    margin: 0 auto;
}
@media (max-aspect-ratio: 16/9) {
    #video_autoplay {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
}
.section-home {
    width: 100%;
    padding-top: 0;
}
.container-navbar {
    background-color: transparent;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 10;
}
.background-black {
    background-color: black;
}
.navbar {
    height: 100px;
    width: 100%;
    position: fixed;
    top: 0;
    background: transparent;
    color: white;
    padding: 15px 100px;
    z-index: 100;
    display: flex;
    align-content: center;
}
.navbar-logo, .navbar-logo img {
    height: 70px;
}
.navbar-links a {
    margin: 0px 15px;
    transition: .3s;
    text-transform: uppercase;
    font-family: "Antonio";
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 50px;
}
.text-shadow-active a {
    text-shadow: none;
}
.navbar-link {
    color: white;
}
.container-brands h2 {
    margin-bottom: 150px;
}
.navbar-links a:last-of-type {
    margin-right: 0;
}
.navbar-link:hover {
    color: #4ac1ed !important;
    cursor: pointer;
    text-shadow: none;
}
.container-flex {
    background-color: black;
    min-height: 100vh;
}
#formModal .modal-dialog {
    box-shadow: #4ac1ed  0px 8px 24px;
    max-width: 400px;
    margin-top: 25vh;
}
#formModal .close {
    top: -10px;
}
.formModal-message {
    font-family: 'Antonio';
    color: white;
    font-size: 22px;
    padding: 50px 25px;
}
@media (max-width: 1500px) {
    .volume_controls {
        display: none;
    }
    .modal-dialog {
        max-width: 70%;
        margin: 30px auto;
        top: 10%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .container-content_about {
        width: 100%;
    }
    .navbar {
        padding: 0px 100px;
        height: 100px;
    }
    .navbar-logo img, .navbar-logo {
        height: 50px;
    }
    .testimonials {
        padding: 25px 0;
    }
    .container-brands {
        padding-bottom: 100px;
    }
    .container-testimonials {
        width: 50%;
    }
    .contact-form {
        padding: 15px;
        width: 60%;
    }
}
@media (max-width: 1100px) {
    .modal-dialog {
        max-width: 85%;
        margin: 30px auto;
        top: 5%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .navbar {
        padding: 15px 50px;
        height: 80px;
    }
    .container-navbar {
        height: 80px;
    }
    .testimonials {
        padding: 0;
    }
    .container-brands {
        padding-bottom: 100px;
    }
    .container-testimonials {
        width: 75%;
    }
}
@media (max-width: 850px) {
    .modal-dialog {
        max-width: 100%;
        margin: 30px auto;
        top: 15%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .close {
        right: 10px;
        top: -40px;
    }
    #formModal .close {
        top: 0;
    }
    .navbar-links {
        display: none;
    }
    .button-mobile-menu, .mobile-menu {
        display: block;
    }
    .navbar-logo {
        position: fixed;
        height: 35px;
        left: 15px;
        top: 15px;
    }
    .container-testimonials {
        width: 100%;
        padding: 0 25px;
    }
    .contact-form {
        padding: 15px;
        width: 100%;
    }
    .container-brands {
        padding: 150px 25px;
        padding-top: 0;
    }
    .container-brands h2 {
        padding-bottom: 50px;
    }
    .navbar {
        background-color: black;
    }
}
@media (max-width: 575px) {
    .content_about {
        padding: 0px 15px;
    }
}


.brands {
    margin: 0 -5px;
    width: calc(100% + 10px);
}
.slide {
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  height: 450px;
  justify-content: center;
}
.slide--brand {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  transition: opacity 0.4s;
  display: flex;
}
.slide--testimonials {
    height: auto;
    padding-bottom: 10px;
}
.slide img {
    -o-object-fit: contain;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    max-width:100%;
    max-height:100%;
    height: auto;
    width:auto;
}
</style>

<script>
import Intersect from 'vue-intersect'

export default {
    data() {
        return {
            on_home: false,
            on_about: false,
            on_portfolio: false,
            on_contact: false,
            fade1: false,
            fade2: false,
            fade3: false,
            fade4: false,
            mobile_menu_active: false,
            showcase_sound: false,
            form: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                message: ''
            },
            form_modal_message: '',
            video_error_handler: ''
        }
    },
    methods: {
        toggle_showcase_sound() {
            document.getElementById("video_autoplay").muted = this.showcase_sound;
            this.showcase_sound = !this.showcase_sound;
        },
        set_showcase_src(src) {
          document.getElementById("iframe_showcase").src = src;
        },
        close_showcase() {
            document.getElementById("iframe_showcase").src = "";
        },
        toggle_mobile_menu() {
            this.mobile_menu_active = !this.mobile_menu_active;
        },
        back_to_top() {
            var rootElement = document.documentElement;
            rootElement.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        },
        scroll_to(id) {
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
            this.mobile_menu_active = false;
        },
        encode(data) {
            return Object.keys(data)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join('&')
        },
        handle_submit() {
            fetch('/', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: this.encode({
                    'form-name': 'contact',
                    ...this.form
                })
            })
            .then(() => this.show_success_modal()
            .catch(() => this.show_error_modal()));
        },
        show_success_modal() {
            this.form_modal_message = `Thank you for your message, we'll contact you as soon as possible!`;
            this.form.firstname = '';
            this.form.lastname = '';
            this.form.email = '';
            this.form.phone = '';
            this.form.message = '';
        },
        show_error_modal() {
            this.form_modal_message = `Something went wrong, please try again later.`;
        }
    },
    components: {
        Intersect
    },
    mounted() {
         var w = window.matchMedia("(max-width: 700px)");
        var vid = document.getElementById("video_autoplay");
        var source = document.createElement("source");
        source.id = "hvid";
        source.setAttribute("type", "video/mp4");
        vid.appendChild(source);

        if (w.matches) {
            vid.pause();
            source.removeAttribute("src");
            source.setAttribute("src", "./static/landing_video_prerelease-short.mp4");
            vid.load();
            vid.play().catch((e)=>{
                this.video_error_handler = e;
            })
        } else {
            vid.pause();
            source.removeAttribute("src");
            source.setAttribute("src", "./static/landing_video_prerelease-short.mp4");
            vid.load();
            vid.play().catch((e)=>{
                this.video_error_handler = e;
            })
        }

        window.addEventListener("resize", function(){
            var w = window.matchMedia("(max-width: 700px)");
            var vid = document.getElementById("video_autoplay");
            var source = document.getElementById("hvid");

            if (w.matches) {
                vid.pause();
                source.src = "./static/landing_video_prerelease-short.mp4";
                vid.load();
                vid.play().catch((e)=>{
                    this.video_error_handler = e;
                })
            } else {
                vid.pause();
                source.src = "./static/landing_video_prerelease-short.mp4";
                vid.load();
                vid.play().catch((e)=>{
                    this.video_error_handler = e;
                })
            }
        });
    }
}
</script>
